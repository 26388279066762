import { useFormikContext } from 'formik';
import Papa from 'papaparse';
import type { ReactNode } from 'react';
import { useCrossPlotData } from '~/components/dataSearch/useCrossPlotData';
import {
  useMeasurementStatistics,
  useRegressionStatistics,
} from '~/components/statistics/MeasurementGraph/useMeasurementStatistics';
import { saveAsCSV, timestamp } from '~/utils/export';
import type { DataSearchFormValues } from '~/utils/modules/dataSearch';

type ExportRow = {
  Name: string;
  x: string;
  y?: string | null;
};

export function ExportStatistics({
  dataX,
  dataY,
  children,
}: {
  dataX: number[];
  dataY?: number[] | null;
  children: (exportData: () => void, canExport: boolean) => ReactNode;
}) {
  const { dataCrossPlot } = useCrossPlotData();
  const { values } = useFormikContext<DataSearchFormValues>();

  const stats = useMeasurementStatistics(dataX, dataY);
  const reg = useRegressionStatistics(
    dataCrossPlot,
    values.crossPlot.logScaleX,
    values.crossPlot.logScaleY,
  );

  function handleExport() {
    const csvData: ExportRow[] = Object.keys(stats).map(key => ({
      Name: key,
      x: stats[key as keyof typeof stats]?.x ?? '',
      y: stats[key as keyof typeof stats]?.y ?? '',
    }));

    csvData.push(
      { Name: 'Regression', x: reg.regressionFormula },
      { Name: 'R-Squared', x: reg.r2 },
    );

    const rows = Papa.unparse(csvData, { header: true });
    saveAsCSV(rows, `Data Export ${timestamp()}.csv`);
  }

  const canExport = dataX.length > 0;

  return children(handleExport, canExport);
}
